import classNames from 'classnames';
import { FC, useRef, useState } from 'react';
import useOnClickOutside from '~/hooks/useOnClickOutside';
import ChevronDown from '~/icons/ChevronDown';
import MiddleDot from '~/icons/MiddleDot';

import styles from './SortButton.module.scss';

const sortValues = [
  { label: 'Now', value: 'period_now' },
  { label: 'Today', value: 'period_today' },
  { label: 'This Week', value: 'period_week' },
  { label: 'This Month', value: 'period_month' },
  { label: 'This Year', value: 'period_year' },
  { label: 'All Time', value: 'period_all_time' },
];

interface Props {
  sortOrder: string;
  setSortOrder: (newSort: string) => void;
}

const SortButton: FC<Props> = ({ sortOrder, setSortOrder }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const componentRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(componentRef, () => setShowDropdown(false));

  return (
    <div ref={componentRef} className={styles.sortHolder}>
      <button
        onClick={() => setShowDropdown((prev) => !prev)}
        className={classNames(styles.sortButton, {
          [styles.isActive]: !!showDropdown,
        })}
      >
        <span>
          <span className={styles.heading}>
            Sort by <MiddleDot />
          </span>
          {sortValues.find((value) => value.value === sortOrder)?.label}
        </span>
        <ChevronDown />
      </button>
      {!!showDropdown && (
        <div className={styles.dropdown}>
          {sortValues.map((value, index) => (
            <button
              onClick={() => {
                setSortOrder(value.value);
                setShowDropdown(false);
              }}
              key={index}
              className={styles.dropdownButton}
            >
              {value.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default SortButton;
